$(function(){
    // top menu toggle
    $(".menu_open .menu_icon").on("click", function () {
        $(".nav_items").toggleClass("nav_open");
        if ($(window).width() < 1025) {
            $("body").toggleClass("menu-active");
        }
    });
    $(".nav_close").on("click", function () {
        $(".nav_items").removeClass("nav_open");
        if ($(window).width() < 1025) {
            $("body").removeClass("menu-active");
        }
    });

    // top menu
    function applyNavigationToggle() {
        if (window.innerWidth < 1025) {
            $('.header_wrapper .nav_items .nav_link > a .expand').on('click', function(e) {
                e.preventDefault();
                $('.sub_menu').removeClass('active');
                $(this).parents('a').siblings('.sub_menu').addClass('active');
            });

            $('.sub_menu .nav_collapse').on('click', function(e) {
                e.preventDefault();
                $(this).closest('.sub_menu').removeClass('active');
            });
        } else {
            $('.header_wrapper .nav_items .nav_link > a .expand').off('click');
            $('.sub_menu .nav_collapse').off('click');
        }
    }
    applyNavigationToggle();
    $(window).resize(function() {
        applyNavigationToggle();
    });

    // Navigation
    $(".destination_header_wrapper .nav_link").on("click", function() {
        $(".destination_header_wrapper .nav_link").removeClass('active');
        $(this).addClass('active');

        $(".destination_content").removeClass("active");
        const targetContent = $(this).data('target');
        $("#" + targetContent).addClass("active");
    });

    $('.destination_content_close').on("click", function() {
        $('.destination_content').removeClass("active");
        $('.destination_header_wrapper .nav_link').removeClass("active");
    });

    $(".destination_content_container .continent a").on("click", function() {
        $('.continent a.active').removeClass('active');
        $(this).addClass('active');
    });
});
